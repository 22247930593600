<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <routerVue :breadcrumbsItems="breadcrumbsItems" />
            </template>
        </DxItem>
    </DxToolbar>
    <div class="row">
        <div class="xs12" style="padding: 6px 0px 6px 24px">
            <DxButton
                type="normal"
                icon="mdi mdi-refresh"
                styling-mode="contained"
                hint="Tải lại"
                @click="loadData()"
            />
            <DxButton
                type="normal"
                icon="mdi mdi-plus-circle"
                styling-mode="contained"
                class="ml-2"
                @click="ThemKyHieu()"
                hint="Thêm mới"
            />
            <DxButton
                type="normal"
                icon="mdi mdi-pencil"
                styling-mode="contained"
                class="ml-2"
                @click="SuaKyHieu(this.arrSelected[0])"
                :disabled="arrSelected.length != 1"
                hint="Sửa"
            />
            <DxButton
                type="normal"
                icon="mdi mdi-delete"
                styling-mode="contained"
                class="ml-2"
                :disabled="arrSelected.length != 1"
                @click="XoaKyHieu(arrSelected[0])"
                hint="Xóa"
            />
            <!-- :disabled="arrSelected.length == 0" -->
    
        </div>
    </div>

    <div class="row pl-4 pr-4">
        <div class="xs12">
            <DxDataGrid
                id="gridContainer"
                class="table-page"
                :remote-operations="false"
                :show-borders="true"
                :data-source="DanhSachKyHieu"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-row-lines="true"
                ref="DanhSachKyHieu"
                :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
                no-data-text="Không có dữ liệu!"
                height="calc(100vh - 56px - 48px - 16px)"
                @cellHoverChanged="onCellHoverChanged"
                @selection-changed="onSelectionChanged"
            >
                <DxHeaderFilter :visible="true" :allow-search="true" />
                <DxPaging :page-size="50" />
                <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                <DxColumnFixing :enabled="true" />
                <DxFilterRow :visible="true" />
                <DxSelection
                    select-all-mode="allPages"
                    show-check-boxes-mode="always"
                    mode="multiple"
                />
                <DxColumn
                    data-field="kyHieu"
                    caption="Ký hiệu"
                    alignment="left"
                    :fixed="true"
                />
                <DxColumn
                    data-field="mauSo"
                    caption="Mẫu số"
                    alignment="left"
                    :fixed="true"
                />

                <DxColumn
                    data-field="tenKieuHoaDon"
                    alignment="left"
                    caption="Kiểu hóa đơn"
                />
                <!-- :group-index="0" -->
                <DxColumn
                    data-field="loaiHoaDon"
                    alignment="left"
                    caption="Loại hóa đơn"
                />
                <DxColumn
                    data-field="templateCode"
                    alignment="left"
                    caption="Template Code"
                />
                <DxColumn
                    data-field="phanTramThue"
                    alignment="left"
                    caption="Phần trăm thuế"
                />
                <DxColumn
                    data-field="phanTramGiamTru"
                    alignment="left"
                    caption="Phần trăm giảm trừ"
                />
                <DxColumn
                    data-field="hoaDonCoMa"
                    alignment="left"
                    caption="Hóa đơn có mã"
                    cell-template="diff-cell-template"
                    :fixed="true"
                    fixed-position="right"
                />

                <template #diff-cell-template="{ data }">
                    <div
                        :style="`color:${
                            data.data.hoaDonCoMa ? '#13b000' : '#d10909'
                        }`"
                    >
                        {{
                            data.data.hoaDonCoMa
                                ? "Hóa đơn có mã"
                                : "Hóa đơn không có mã"
                        }}
                    </div>
                </template>

                <DxSummary>
                    <DxTotalItem
                        column="kyHieu"
                        summary-type="count"
                        display-format="Tổng: {0}"
                        :show-in-group-footer="true"
                        :align-by-column="true"
                    />
                </DxSummary>
                <!-- Hover button -->
                <DxColumn
                    cssClass="column-button-hover"
                    data-field="Detail"
                    caption="Trạng thái"
                    width="1"
                    cell-template="buttons-cell"
                    :fixed="true"
                    fixed-position="right"
                ></DxColumn>
                <template #buttons-cell="{ data }">
                    <div class="buttons">
                        <DxButton
                            id=""
                            icon="mdi mdi-pencil"
                            @click="SuaKyHieu(data.data)"
                            hint="Sửa"
                        ></DxButton>
                        <DxButton
                            id=""
                            icon="mdi mdi-delete"
                            class="mx-2"
                            @click="XoaKyHieu(data.data)"
                            hint="Xóa"
                        ></DxButton>
                    </div>
                </template>
            </DxDataGrid>
        </div>
    </div>
    <DxPopup
        v-model:visible="popupXacNhanXoa"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="false"
        :width="388"
        height="auto"
    >
        <DxValidationGroup ref="formValidation">
            <div class="row justify-center font-20 font-medium mb-3">
                Xác nhận xóa cấu hình
            </div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxTextBox
                        v-model="LyDoXoa"
                        label="Lý do xóa (*)"
                        labelMode="floating"
                        styling-mode="outlined"
                        validationMessageMode="always"
                    >
                        <DxValidator>
                            <DxRequiredRule
                                message="Lý do xóa không được bỏ trống!"
                            />
                        </DxValidator>
                    </DxTextBox>
                </div>
            </div>

            <div class="row justify-space-between">
                <DxButton
                    text="Hủy"
                    type="default"
                    styling-mode="text"
                    @click="XacNhanXoa(false)"
                />
                <DxButton
                    text="Xác nhận"
                    type="default"
                    styling-mode="contained"
                    @click="XacNhanXoa(true)"
                />
            </div>
        </DxValidationGroup>
    </DxPopup>
</template>

<script>
import { mapState } from "vuex";

import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxFilterRow,
    DxTotalItem,
    DxScrolling,
    DxRequiredRule,
} from "devextreme-vue/data-grid";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
import {
    DxButton,
    DxPopup,
    DxTextBox,
    DxValidator,
    DxValidationGroup,
} from "devextreme-vue";
import { DxHeaderFilter, DxColumnFixing } from "devextreme-vue/data-grid";
import PopupXacNhan from "@sonphat/common-v1/components/PopupXacNhan.vue";
import { DxPaging } from "devextreme-vue/data-grid";
export default {
    components: {
		DxHeaderFilter,
		DxPaging,
		DxColumnFixing,
        DxValidationGroup,
        DxToolbar,
        DxItem,
        routerVue,
        DxButton,
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxFilterRow,
        DxSummary,
        DxTotalItem,
        DxPopup,
        PopupXacNhan,
        DxTextBox,
        DxScrolling,
        DxValidator,
        DxRequiredRule,
    },

    data() {
        return {
            LyDoXoa: "",
            DataXoa: null,
            popupXacNhanXoa: false,
            arrSelected: [],
            paramXacNhanXoa: {
                WidthTitle: "90%",
                Title: "Xác nhận xóa ký hiệu",
                btnLeft: "HỦY",
                btnRight: "ĐỒNG Ý",
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_DieuDo",
                    text: "Danh sách ký hiệu",
                    disabled: true,
                    to: "",
                },
                // {
                //     id: "breadcrums_DieuDo",
                //     text: this.$t("stringRouter.QLBD"),
                //     disabled: true,
                //     to: "",
                // },
            ],
        };
    },
    created() {
        this.LayDanhSachKyHieu();
    },

    computed: {
        ...mapState({
            DanhSachKyHieu: (state) => state.CongHoaDonDienTu.DanhSachKyHieu,
        }),
    },

    methods: {
        ThongBao(type, message) {
            let modelToast = {
                isVisible: true,
                message: message,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        loadData() {
            this.LayDanhSachKyHieu();
        },
        async LayDanhSachKyHieu() {
            let rs = await this.$store.dispatch(
                "CongHoaDonDienTu/LayDanhSachKyHieu",
                {
                    guidBenXe: this.$Core.Auth.TokenParsed.GuidDoanhNghiep,
                }
            );
        },
  

        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        onSelectionChanged(e) {
            this.arrSelected = e.selectedRowsData;
        },
        ThemKyHieu() {
            this.$router.push("/Cong-Hoa-Don-Dien-Tu/Them-Sua-Ky-Hieu");
        },
        SuaKyHieu(data) {
            this.$router.push({
                path: "/Cong-Hoa-Don-Dien-Tu/Them-Sua-Ky-Hieu",
                query: {
                    guidKyHieu: data.guidKyHieu,
                },
            });
            console.log(data.guidKyHieu);
        },
        XoaKyHieu(data) {
            this.DataXoa = data;
            console.log(this.DataXoa);
            this.popupXacNhanXoa = true;
        },
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        async XacNhanXoa(bool) {
            if (bool == true) {
                if (!this.validate().isValid) {
                    this.$Helper.Common.autoFocusError(this.validate());
                } else {
                    this.popupXacNhanXoa = false;
                    try {
                        let rs = await this.$store.dispatch(
                            "CongHoaDonDienTu/XoaKyHieu",
                            {
                                guidCauHinh: this.DataXoa.guidKyHieu,
                                guidLoaiHoaDon: this.DataXoa.loaiHoaDon,
                                lyDoHuy: this.LyDoXoa,
                            }
                        );
                        console.log("rs xóa", rs);
                        if (!rs.status) {
                            this.ThongBao(
                                "error",
                                `xóa thất bại! ${rs.message}`
                            );
                        } else {
                            this.ThongBao("success", `Xóa thành công!`);
                            this.LayDanhSachKyHieu();
                            this.$refs.DanhSachKyHieu.instance.refresh();
                        }
                    } catch (error) {
                        this.$stopLoading;
                    }
                }
            } else if (bool == false) {
                // Huy
                this.popupXacNhanXoa = false;
            }
            this.DataXoa = null;
        },
    },
};
</script>

<style scope>
.table-page
    .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="6"],
td[aria-colindex="7"] {
    text-align: right !important;
}
</style>
